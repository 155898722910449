/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
// import favicon from '../images/favicon.png';
// import logoSquare from '../images/logoSquare.png';

function SEO({ description, lang, meta, title, keywords }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
          }
        }
      }
    `
  );

  // const metaDescription = description || site.siteMetadata.description;

  // const metaKeywords = keywords || site.siteMetadata.keywords;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title} | %s`}
      meta={[
        {
          name: 'google-site-verification',
          content: 'vBSLlam2aiB6NfJ3Ay3iihVMdt9HstRCmcqDdVZd8xQ',
        },
        {
          property: `og:title`,
          content: `Bocciardo`,
        },

        {
          property: `og:description`,
          content: site.siteMetadata.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        // {
        //   property: `og:image`,
        //   content: `${site.siteMetadata.siteUrl}${favicon}`,
        // },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl,
        },
        {
          property: `og:keywords`,
          content: site.siteMetadata.keywords,
        },
        {
          property: `og:author`,
          content: site.siteMetadata.author,
        },
        {
          name: `description`,
          content: site.siteMetadata.description,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: site.siteMetadata.description,
        },

        // {
        //   property: `twitter:image`,
        //   content: `${site.siteMetadata.siteUrl}${favicon}`,
        // },
        // {
        //   property: `image`,
        //   content: `${site.siteMetadata.siteUrl}${favicon}`,
        // },
      ].concat(meta)}
      // link={[
      //   { rel: 'shortcut icon', type: 'image/x-icon', href: `${favicon}` },
      // ]}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  keywords: PropTypes.any,
  image: PropTypes.any,
};

export default SEO;
