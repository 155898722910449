import React from 'react';
import './contactForm.scss';

export default function ContactForm(props) {
  return (
    <div className="contact-form-container">
      <form name="contact" method="POST" data-netlify="true" action="/success">
        <input type="hidden" name="form-name" value="contact" />
        <p>
          <label>
            <input
              type="text"
              name="name"
              placeholder={`${props.language === 'IT' ? 'Nome' : 'Name'}`}
              required
            />
          </label>
        </p>
        <p>
          <label>
            <input type="email" name="email" placeholder="Email" required />
          </label>
        </p>
        <p>
          <label>
            <input
              type="text"
              name="subject"
              placeholder={`${props.language === 'IT' ? 'Oggetto' : 'Subject'}`}
              required
            />
          </label>
        </p>
        <p>
          <label>
            <textarea
              name="message"
              rows="7"
              placeholder={`${
                props.language === 'IT' ? 'La Tua Richiesta' : 'Your Enquiry'
              }`}
              required
            />
          </label>
        </p>
        <p>
          <button type="submit">{props.submit}</button>
        </p>
      </form>
    </div>
  );
}
