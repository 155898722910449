import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './map.scss';

export default class Map extends Component {
  map = null;
  poi = null;
  state = {
    map: null,
  };

  componentDidMount = () => {
    this.renderMap();
  };

  renderMap = () => {
    mapboxgl.accessToken =
      'pk.eyJ1IjoicmFuaWVyaWdhbGFzc28iLCJhIjoiY2pzMGlpdmtrMDM5MjRha3Z5eGd4M3NqOCJ9.K2Xxn6LzavamXgL7JPCDcw';
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 12,
      center: [8.964517, 44.397522],
    });
    this.poi = new mapboxgl.Marker()
      .setLngLat([8.964517, 44.397522])
      .addTo(this.map);
  };

  render() {
    return (
      <div className="map-container">
        <div className="container" id="map" />
      </div>
    );
  }
}
