import React from 'react';
import SEO from '../components/seo';
import Map from '../components/map';

import '../components/index.scss';
import Loader from '../components/loader';
import ContextConsumer from '../components/context';
import ContactForm from '../components/contactForm';
import './stylesheets/contact_us.scss';

const EN = require('../components/copy/EN.json');
const IT = require('../components/copy/IT.json');
let currentLanguage;
let language;

interface Props {}
interface State {
  isLoading: boolean;
}

class ContactUs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  loader() {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  renderIndex() {
    return (
      <div className="container">
        <ContextConsumer>
          {({ data }) => {
            language = data.contextLanguage;

            language === 'IT' ? (currentLanguage = IT) : (currentLanguage = EN);
            return (
              <>
                {console.log(currentLanguage.Contact.title)}
                <SEO title="Bocciardo" />
                <h2>{currentLanguage.Contact.title}</h2>
                <p>{currentLanguage.Contact.subtitle}</p>
                <ContactForm
                  submit={currentLanguage.Contact.submit}
                  language={language}
                />
                <div className="contact-info">
                  <p>
                    <strong>Bocciardo Brokers s.r.l.</strong>
                  </p>

                  <p>
                    <strong>Legal Office : </strong>Via San Vincenzo 1/4 - 16121
                    Genoa
                  </p>

                  <p>
                    {' '}
                    <strong>Head Office : </strong>Piazza Leonardo da Vinci 5/8
                    - 16146 Genoa
                  </p>

                  <p>
                    <strong>Vat Number : </strong>02552430999
                  </p>

                  <p>
                    <strong>Email : </strong>info@bocciardo.com
                  </p>

                  <p>
                    <strong>Phone : </strong>+39 010 3628882
                  </p>
                </div>
                <Map />
              </>
            );
          }}
        </ContextConsumer>
      </div>
    );
  }

  render() {
    return <>{this.renderIndex()}</>;
  }
}

export default ContactUs;
